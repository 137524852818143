import React from 'react';
import { Seo } from '../components/layout/seo';
import Service from '../components/services/service';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

class BreastChestHealth extends React.Component {
  render() {
    const data = get(this, 'props.data.contentfulService');
    const Img1 = getImage(data.squareImg.gatsbyImageData);
    const Img2 = getImage(data.portraitImage.gatsbyImageData);
    return (
      <Service
        title={data.name}
        position="Practitioner"
        caption={data.caption}
        info={data.description.childMarkdownRemark.html}
        imgSquare={Img1}
        imgPortrait={Img2}
        list1type={data.list1type}
        list2type={data.list2type}
        list2display={data.list2display}
        list1text={data.list1text.childMarkdownRemark.html}
        list2text={data.list2text.childMarkdownRemark.html}
        list1title={data.list1title}
        list2title={data.list2title}
        secondary={data.secondary}
        secondaryInfo={data.secondaryInfo.childMarkdownRemark.html}
        schedDisplay
        col1sec1
        col1sec2
        col2sec1
        col2sec2
        col3sec1
        offeredBy={data.offeredBy}
      />
    );
  }
}

export const Head = ({ location, data }) => {
  const description =
    data.contentfulService.description.childMarkdownRemark.html
      .replace('<p>', '')
      .replace('</p>', '');
  return (
    <Seo
      title={data.contentfulService.name}
      pathname={location.pathname}
      description={description}
    />
  );
};
export default BreastChestHealth;

export const pageQuery = graphql`
  query {
    contentfulService(name: { eq: "Breast/Chest Health" }) {
      name
      caption
      description {
        childMarkdownRemark {
          html
        }
      }
      secondary
      secondaryInfo {
        childMarkdownRemark {
          html
        }
      }
      list1type
      list2type
      list2display
      list1title
      list1text {
        childMarkdownRemark {
          html
        }
      }
      list2title
      list2text {
        childMarkdownRemark {
          html
        }
      }
      portraitImage {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
      squareImg {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
      offeredBy {
        name
        title
        index
        image {
          description
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
